
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .row {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }
}
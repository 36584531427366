
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 41px;
  width: 100%;

  @include tablet {
    padding-top: 0;
    margin-top: -7px;
  }

  @include mobile {
    margin-top: 1px;
  }

  img {
     margin-top: -50px;
    @include mobile {
      width: 100%;
      margin-left: 27px;
    }
  }

  p {
    margin-top: 1px;

    @include tablet {
      margin-top: -4px;
    }

    @include mobile {
      margin-top: 7.5px;
      text-align: center;
    }
  }

  button {
    margin-top: 44px;

    @include tablet {
      margin-top: 40px;
    }

    @include mobile {
      margin-top: 26.5px;
    }
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .recurring_icon {
    position: absolute;
  }

  .text_wrapper {
    width: calc(100% - 64px);
  }

  .empty_transactions {
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--secondary-80);
    background: #FFF;
  }

  .table_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid var(--secondary-80);
    background: var(--secondary-100);
    padding: 16px;
    transition: .3s;

    @include mobile {
      flex-direction: column;
      gap: 16px;
    }

    &:hover {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
    }

    .right {
      @include mobile {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }

    .left {
      display: flex;
      gap: 24px;
      align-items: center;
      text-align: left;

      @include mobile {
        width: 100%;
      }

      .icon {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--primary-80);
        position: relative;

        @include mobile {
          width: 40px;
          height: 40px;
        }

        &.isWallet {
          background: var(--primary-50);
        }

        svg {
          color: var(--neutral-20);
        }
      }
    }

    .amount {
      @include mobile {
        font-size: 16px;
      }
      &.plus {
        color: var(--success-40);
      }
      &.isCancel {
        color: var(--error-40)
      }
    }

    .status {
      padding: 2px 19px;
      font-size: 14px;
    }
  }

  .action {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .skeleton {
    height: 87px;
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: space-between;
  height: calc(100% - 57px);

  .content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .chats_wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .chat_wrapper {
      display: flex;
      padding: 8px 12px;
      gap: 8px;
      transition: .3s;
      cursor: pointer;
      border-radius: 8px;

      &:hover {
        background: var(--primary-90);
      }

      .icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--secondary-20);
        border-radius: 100px;

        svg {
          color: var(--primary-50);
        }
      }

      .chat_content {
        width: calc(100% - 40px)
      }

      .chat_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .message {
        color: var(--neutral-40);
        white-space: nowrap;
        overflow: hidden;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        max-height: 50px;
        line-clamp: 2;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 16px;
    border-top: 1px solid var(--secondary-80);
  }
}
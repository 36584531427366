
                    @import "src/styles/_mixins.scss";
                
.cashback_wrapper {
  border-radius: 10px;
  border: 1px solid var(--secondary-80);
  background: var(--secondary-100);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 360px;
  min-width: 282px;
  max-width: 100%;
  transition: .3s;
  margin-top: 48px;

  @include mobile {
    margin-top: 16px;
    padding: 16px;
  }

  &:hover {
    background: var(--primary-100);
  }

  .header {
    display: flex;
    align-items: center;
    gap: 16px;

    .icon {
      border-radius: 30px;
      background: var(--primary-80);
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .cashback_content {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .info {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .link {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    text-decoration: underline;
    color: var(--secondary-20)
  }
}
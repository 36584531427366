
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  position: fixed;
  top: 104px;
  right: 0;
  background: var(--secondary-90);
  z-index: 20;
  padding: 0 32px;
  transition: .3s;
  display: flex;
  align-items: center;

  @media (max-width: 1457px) {
    top: 88px;
  }

  @media (max-width: 1038px) {
    top: 109px;
  }

  @media (max-width: 1023px) {
    top: 102px;
  }

  @media (max-width: 970px) {
    top: 80px;
  }

  @include mobile {
    padding: 0 16px;
    width: 100%;
    max-width: 100%;
  }

  transform: translateX(100%);

  &.isOpen {
    transform: translateX(0);
  }

  .details {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 88px);
    padding: 72px 16px 72px 0;
    max-width: 495px;
    position: relative;
    transition: .3s;
    background: var(--secondary-90);

    transform: translateX(100%);
    width: 0;
    overflow: hidden;

    @include tablet {
      padding: 72px 0;
    }

    .details_button {
      margin-top: 16px;
    }

    .header_icon {
      display: flex;
      align-items: center;
      gap: 12px;

      .icon {
        border-radius: 20px;
        background: var(--Primary-P-70, #FFE9C0);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &.isOpen {
      transform: translateX(0);
      overflow-y: auto;
      width: 495px;
      padding: 72px 32px 72px 0;
      border-right: 1px solid var(--secondary-80);
      margin-right: 16px;

      @include tablet {
        width: 100%;
        max-width: 100%;
        z-index: 40;
        border-right: 0;
        padding: 72px 0;
        margin: 0;
      }
    }

    .title_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--secondary-80);

      .actions {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }

    .notification_content {
      & > .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 12px 0;

        p {
          span {
            font-weight: 600;
          }
        }
      }

      .body {

        .load_more_button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }

    .close_button {
      right: 32px;

      @include tablet {
        right: 0;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    max-width: 495px;
    overflow-y: auto;
    height: calc(100vh - 88px);
    padding: 72px 0;
    position: relative;
    width: 450px;
    background: var(--secondary-90);

    @include tablet {
      overflow: hidden;
      max-width: 100%;
      transform: translateX(0);
      width: 100%;

      &.isDetailsOpen {
        width: 0;
        transform: translateX(100%);
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--secondary-80);

      .actions {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
  }

  .close_button {
    position: absolute;
    top: 23px;
    right: 0;

    @media (max-width: 970px) {
      top: 8px;
    }
  }

  .notifications_wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top: 16px;

    .skeleton {
      width: 100%;
      height: 120px;
    }

    .notification_block {
      display: flex;
      flex-direction: column;

      .month {
        padding: 8px 0;
        border-bottom: 1px solid var(--secondary-80);
      }

      .notification_wrapper {
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 8px;
        gap: 8px;
        border-bottom: 1px solid var(--secondary-80);

        .icon {
          border-radius: 20px;
          background: var(--Primary-P-70, #FFE9C0);
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .text_wrapper {
          display: flex;
          flex-direction: column;
          gap: 4px;
          width: calc(100% - 55px);

          @include mobile {
            width: auto;
          }

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .description {
            font-size: 14px;
            padding-right: 20px;
          }
        }

        .time {
          position: absolute;
          right: 8px;
          top: 10px;
          color: var(--neutral-60);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }

        .status {
          position: absolute;
          right: 16px;
          bottom: 16px;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background: var(--neutral-60);

          &.red {
            background: var(--error-50);
          }
        }
      }
    }
  }
}

.overlay {
  width: 100vw;
  position: absolute;
  right: 0;
  background: rgba(0,0,0,0.2);
  height: 100vh;
  top: 0;
  z-index: 10;

  @media (max-width: 1457px) {
    top: 88px;
  }

  @media (max-width: 1038px) {
    top: 109px;
  }

  @media (max-width: 1023px) {
    top: 102px;
  }

  @media (max-width: 970px) {
    top: 80px;
  }
}

                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: 10px;
    text-align: center;

    @include tablet {
      margin-bottom: 8px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 111px;
    margin-top: 48px;

    @include tablet {
      margin-top: 44px;
      gap: 44px;
    }

    @include mobile {
      margin-top: 26px;
      gap: 41px;
    }

    .cards_wrapper {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
        gap: 48px;
      }

      .card {
        cursor: pointer;
        width: calc(50% - 24px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        max-width: 350px;
        border-radius: 20px;
        border: 1px solid var(--primary-50);
        background: var(--primary-100);
        padding: 48px;

        &.disabled {
          cursor: not-allowed;
          border: 1px solid var(--secondary-60);
          background: var(--secondary-90);
          opacity: .5;
        }

        @include mobile {
          width: 100%;
          padding: 32px;
        }

        .title {
          margin-bottom: 8px;
        }

        .description {
          margin-bottom: 16px;
        }

        .check_row {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          text-align: left;
          margin-bottom: 16px;

          svg {
            color: var(--primary-30);
          }

          p {
            width: calc(100% - 28px);
          }
        }

        .radio {
          margin-top: 32px;

          @include mobile {
            margin-top: 8px;
          }
        }
      }
    }
  }

}



@include mobile {

}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  position: relative;
  justify-content: space-between;

  .line {
    content: "";
    background: var(--neutral-70);
    position: absolute;
    top: 15px;
    height: 2px;
  }

  .step {
    display: flex;
    flex-direction: column;
    gap: 9px;
    position: relative;
    align-items: center;
    width: 94px;

    & > p {
      color: var(--neutral-60);
    }

    &:first-child {
      p {
        margin-left: 20%;
      }
    }

    .point {
      border: 2px solid var(--neutral-60);
      background: var(--secondary-20);
      border-radius: 100px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;


      i {
        background: var(--neutral-50);
        border-radius: 100px;
        width: 10px;
        height: 10px;
      }
    }

    &.isDoneStep {
      .point {
        background: var(--primary-50);
        border-color: var(--primary-50);

        svg {
          color: var(--secondary-30);
        }
      }

      p {
        color: var(--primary-50);
      }
    }

    &.isCurrentStep {
      .point {
        border-color: var(--primary-50);

        i {
          background: var(--primary-50);
        }
      }

      p {
        color: var(--primary-50);
      }
    }
  }

  &.light {

    .line {
      background: var(--secondary-20);
    }

    .step {

      & > p {
        color: var(--neutral-60);
      }

      &:first-child {
        p {
          margin-left: 0;
        }
      }

      .point {
        border-color: var(--secondary-20);
        background: var(--neutral-100);

        i {
          background: none;
        }
      }

      &.isDoneStep {
        .point {
          background: var(--secondary-20);

          svg {
            color: var(--secondary-100);
          }
        }

        p {
          color: var(--secondary-20)
        }
      }

      &.isCurrentStep {
        .point {
          border-color: var(--secondary-20);

          i {
            background: var(--secondary-20);
          }
        }

        p {
          color: var(--secondary-20)
        }
      }
    }
  }

  &.large {
    .line {
      top: 15px;
    }

    .step {
      width: 145px;
      .point {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.mini_progress {
  width: 100%;
  background: var(--primary-70);
  height: 4px;

  span {
    height: 100%;

    &.isDone {
      background: var(--primary-40);
    }
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    width: 100%;
  }

  .radio_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    border-bottom: 1px solid var(--secondary-80);
    margin-bottom: 24px;
  }

  .same_address {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    max-width: 600px;
  }

  .title {
    margin-bottom: 12px;
    text-align: center;
  }

  .description {
    margin-bottom: 48px;
    text-align: center;

    @include tablet {
      margin-bottom: 50px;
    }

    @include mobile {
      margin-bottom: 24px;
    }
  }

  .form {
    width: 584px;
    margin-bottom: 71px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      width: 100%;
    }

    .input_wrapper {
      width: 100%;
      display: flex;
      gap: 24px;
      margin-bottom: 24px;

      @include mobile {
        flex-direction: column;
      }
    }

    .street {
      margin-bottom: 24px;
    }

    .next {
      margin-top: 48px;
      margin-bottom: 50px;

      @include tablet {
        margin-top: 32px;
        margin-bottom: 32px;
      }

      @include mobile {
        margin-top: 33px;
        margin-bottom: 26px;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

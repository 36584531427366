
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .skeleton {
        width: 200px;
        height: 120px;
    }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  justify-content: center;
  padding-left: 32px;

  @include tablet {
    padding-left: 0;
  }

  .content_wrapper {
    background: var(--primary-100);
    min-height: calc(100vh - 88px);
    height: max-content;
    width: 0;
    transition: .3s;
    padding-top: 40px;
    padding-bottom: 40px;

    @include mobile {
      transition: none;
    }

    &.isOpen {
      padding-right: 60px;
      padding-left: calc(50% - 710px);
      width: calc(100% - 400px);

      @media (max-width: 1200px) {
        padding-left: 32px;
      }

      @include tablet {
        margin-right: 111px;
        padding-left: 16px;
        padding-right: 16px;
        width: calc(100% - 110px);
      }

      @include mobile {
        padding: 30px 24px;
      }

    }

    @include tablet {
      margin-right: 0;
    }

    .content {
      width: 100%;
    }
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 1475px;
    margin: 0 auto;
    padding: 56px 47px 47px;
    transition: .3s;

    .burger {
      color: var(--secondary-20);
      display: flex;
      flex-direction: column;
      gap: 3.5px;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      transition: .3s;

      & > div {
        transition: .3s;
        width: 24px;
        height: 2.5px;
        background: var(--secondary-20);
        opacity: 1;
      }

      &.isOpen {
        gap: 0;
        & > div {
          &:first-child {
            transform: rotate(-45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            margin-top: -5px;
            transform: rotate(45deg);
          }
        }
      }

    }

    .title_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      @include tablet {
        height: 22px !important;
        overflow: hidden;
      }

      @include mobile {
        height: 20px !important;
        overflow: hidden;
      }

      &.isOpen {
        justify-content: space-between;
      }
    }

    @media (max-width: 1200px) {
      width: 100%;
      padding: 40px 21px;
    }

    @include mobile {
      padding-top: 34px !important;
    }

    &.isOpen {
      width: 800px;

      @media (max-width: 1200px) {
        width: 580px;
      }

      @include tablet {
        width: 110px !important;
        overflow: hidden;
        padding: 40px 15px 40px 23px;
        position: absolute !important;
        right: 0;
        height: 100vh;
        background: white;
        z-index: 10;
        transition: 0.3s;

        .tab {
          margin-right: 0 !important;
        }
      }
    }

    &.isTabletOpen {
      @include tablet {
        width: 380px !important;
      }
      @include mobile {
        width: 320px !important;
      }
    }

    .divider {
      height: 1px;
      width: 100%;
      background: var(--secondary-80);
    }

    .tab {
      display: flex;
      align-items: center;
      padding: 16px;
      margin-right: 33px;
      gap: 24px;
      font-size: 18px;
      font-weight: 500;
      line-height: 150%;
      transition: .3s;
      border-radius: 10px;
      cursor: pointer;
      width: 100%;

      @include tablet {
        padding: 10px 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;

        & > div {
          height: 22px !important;
          overflow: hidden;
        }
      }

      &:hover, &.isActive {
        background: var(--primary-90);

        & > button {
          background: var(--primary-70);
        }
      }
    }
  }
}
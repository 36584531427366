
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 23px;
    padding: 19px 15px 21px 22px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 312px;
    height: 196px;
    border-radius: 15px;
    cursor: pointer;
    transition: .3s;
    transform-origin: left bottom;

    &:hover {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
        transform: scale(1.03);
    }

    &:active {
        box-shadow: none;
    }

    @include mobile {
        width: 256px;
        height: 160px;
        gap: 16px;
    }

    .amount {
        color: var(--neutral-100);
        font-size: 24px;
        font-weight: 700;

        @include mobile {
            font-size: 20px;
        }
    }

    .content_wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        gap: 4px;

        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .badge {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            color: white;
            padding: 2px 10px;
            border-radius: 10px;
            border: 1px solid white;
            width: max-content;
        }
    }

    .description,
    .number {
        color: var(--neutral-100);
        text-transform: uppercase;
        font-size: 16px;
    }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  justify-content: center;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .left {
    display: flex;
    background: var(--primary-100);
    min-height: calc(100vh - 88px);
    height: max-content;
    overflow: hidden;
    transition: .3s;
    padding: 40px 60px 40px calc(50% - 700px);
    width: 100%;

    @media (max-width: 1400px) {
      padding-left: 32px;
    }

    @include tablet {
      padding-right: 17px;
    }

    @include mobile {
      padding: 18px 24px 24px;
      min-height: calc(100% - 20px);
    }

    .content {
      width: 100%;
    }

    .actions_wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 62px;
      margin-bottom: 24px;

      .action {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 auto;
    padding: 47px;
    transition: .3s;
    width: 680px;

    @media (max-width: 1200px) {
      padding: 40px 21px;
      width: 580px;
    }

    @include tablet {
      width: 540px;
      padding: 40px 15px 40px 23px;
    }

    @include mobile {
      width: auto;
      padding: 40px 24px;
    }

    .actions {
      display: flex;
      align-items: flex-start;
      gap: 43px;

      .action {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        cursor: pointer;

        p {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
}

                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 960px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;

  @include tablet {
    width: 100%;
    gap: 16px;
  }

  @include mobile {
    gap: 24px;
  }

  .content {
    display: flex;
    gap: 24px;

    @include tablet {
      flex-direction: column;
    }

    .left {
      width: 490px;

      @include tablet {
        width: 100%;
      }
    }
    .right {
      width: 442px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      @include tablet {
        width: 100%;
      }
    }
  }

  .back {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;

    @include tablet {
      margin-top: 8px;
    }

    @include mobile {
      margin-top: 0;
    }

    p {
      text-decoration: underline;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 350px;

  @include tablet {
    width: 100%;
    max-width: 100%;
  }

  @include mobile {
    width: 100%;
    max-width: 100%;
  }

  img {
    margin-top: -50px;
  }

  .title {
    margin-bottom: 16px;
    color: var(--primary-30);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    @include tablet {
      margin-top: 16px;
    }
  }

  .description {
    max-width: 400px;
    text-align: center;
    width: 100%;
    margin-bottom: 16px;
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 648px;
  padding: 0 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include mobile {
    width: 100%;
  }

  .card {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--secondary-80);
    background: var(--secondary-100);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      padding: 7.5px 8px;
    }

    &.isActive {
      background: var(--primary-90);
    }

    .left {
      display: flex;
      align-items: center;
      gap: 24px;

      @include mobile {
        gap: 8px;
      }

      & > span {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        background: var(--primary-70);

        @include mobile {
          width: 40px;
          height: 40px;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      & > div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: left;

        @include mobile {
          gap: 0;
        }
      }
    }
  }

  .skeleton {
    height: 93px;
  }
}

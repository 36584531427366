
                    @import "src/styles/_mixins.scss";
                
.main_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .select {
    width: 100px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-right: -100px;
  justify-content: center;

  .page {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--secondary-60);
  }

  .active_page a {
    color: var(--secondary-30) !important;
  }

  .next, .previous {
    margin-bottom: -4px;
  }
}

                    @import "src/styles/_mixins.scss";
                
.main_wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: -50px;

  @media (max-width: 1430px) {
    margin-left: 16px;
  }

  .table_actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 4px;

    .delete {

      svg {
        color: var(--error-40)
      }
    }
  }

  .progress_wrapper {
    border-bottom: 1px solid var(--secondary-80);
    padding-bottom: 12px;
    display: flex;
    justify-content: center;

    .content {
      width: 75%;
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      width: max-content;
    }

    .progress {
      & > span {
        top: 12px !important;
      }
      & > div {
        & > div {
          width: 25px !important;
          height: 25px !important;
          //background: var(--primary-100) !important;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;

    & > .actions {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      z-index: 1;

      .send_disclaimer {
        a {
          color: var(--secondary-20);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
      }

      .limits {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 10px;
        background: var(--primary-90);
        padding: 16px;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;
        border-bottom: 1px solid var(--secondary-80);

        @include mobile {
          flex-direction: column;
          gap: 16px;
          text-align: center;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @include tablet {
          gap: 2px;
        }
      }

      .ddr_info {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.25px;

        svg {
          color: var(--primary-30);
          margin-bottom: -7px;
          margin-right: 4px;
        }

        span {
          font-weight: 600;
        }
      }

      .balance {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--primary-40);
        background: var(--primary-90);
        padding: 16px;
        font-weight: 600;
      }

      .fees_container {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: hidden;
        transition: .3s;
        border-radius: 8px;
        padding: 0 16px;
        background: var(--secondary-100);

        &.isOpen {
          padding: 16px !important;
          border: 1px solid var(--secondary-80);
        }

        .fees_wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 150%;
            }
          }
        }
      }
    }

    .form_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      margin-bottom: 8px;

      .date {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .row {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 24px;
      }
    }

    .new_contact {
      align-self: flex-end;
    }

    .fees {

      .icon {
        width: 10px;
        height: 19px;
        position: relative;
        cursor: pointer;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 2px;
          background-color: #333;
          transition: 0.3s;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(0deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(90deg);
        }

        &.isOpen {
          &:before {
            transform: translate(-50%, -50%) rotate(0deg);
          }

          &:after {
            transform: translate(-50%, -50%) rotate(0deg);
          }
        }
      }

      .fees_container {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: hidden;
        transition: .3s;
        border-radius: 8px;
        padding: 0 16px;
        background: var(--secondary-100);

        &.isOpen {
          padding: 16px !important;
          border: 1px solid var(--secondary-80);
        }

        .fees_wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 150%;
            }
          }
        }
      }
    }
  }

  .notes {
    margin-top: 6px;
    width: 100%;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    .row {
      display: flex;
      gap: 4px;

      span {
        padding-left: 4px;
      }
    }
  }

  .success {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .title {
      color: var(--primary-30);
      text-align: center;
      font-size: 50px;
      font-weight: 700;
      line-height: 120%;
      margin-top: -16px;
    }

    .actions {
      margin-top: 16px;
      display: flex;
      align-items: center;
      gap: 32px;

      button {
        min-width: 180px;
        font-size: 18px;
      }
    }
  }
}
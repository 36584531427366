
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: max-content;

  @include tablet {
    width: 100%;
  }

  @include mobile {
    width: 100%;
  }

  .progressbar {
    max-width: 473px;
    width: 100%;

    @include mobile {
      width: 100%;
      display: none;
    }
  }

  .content {
    padding: 32px 0 24px;
    width: max-content;

    @include tablet {
      width: 100%;
    }

    @include mobile {
      width: 100%;
      padding-top: 0;
    }
  }

  .back {
     align-self: flex-start;
     display: flex;
     align-items: center;
     gap: 8px;
     margin-top: 16px;

     @include tablet {
       margin-top: 8px;
     }

     @include mobile {
       margin-top: 0;
     }

     p {
       text-decoration: underline;
       font-size: 16px;
       font-style: normal;
       font-weight: 500;
       line-height: 150%;
     }
   }
}

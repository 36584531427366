
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;

  img {
    margin-top: -70px;
    margin-bottom: -60px;
  }

  p {
    max-width: 451px;
    width: 100%;
    text-align: center;
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    font-weight: 600;
  }

  .title {
    margin-bottom: 10px;
    text-align: center;

    @include tablet {
      margin-bottom: 8px;
    }
  }

  .description {
    margin-bottom: 57px;

    @include mobile {
      margin-bottom: 25px;
    }
  }

  .form {
    width: 380px;
    margin-bottom: 71px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      margin-bottom: 32px;
    }

    @include mobile {
      width: 100%;
      margin-bottom: 24px;
    }

    & > p {
      margin-bottom: 12px;
      align-self: flex-start;

      @include tablet {
        margin-bottom: 10px;
      }

      @include mobile {
        margin-bottom: 6px;
      }
    }

    button {
      margin-top: 45px;

      @include tablet {
        margin-top: 32px;
      }

      @include mobile {
        margin-top: 33px;
      }
    }
  }

  & > p {
    @include mobile {
      text-align: center;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}



@include mobile {

}


                    @import "src/styles/_mixins.scss";
                
.container {
  display: flex;
  flex-direction: column;

  .wrapper {
    display: flex;
    align-self: center;
    gap: 20px;

    input {
      width: 42px;
      padding: 14.5px 12px;
      border-radius: 5px;
      border: 1px solid var(--neutral-40);
      height: 56px;
      font-size: 16px;
      font-weight: 400;
      line-height: 100%;
      outline: none;

      &:focus-within {
        border: 2px solid var(--primary-50) !important;
      }

      @include mobile {
        width: 32px;
        height: 40px;
        padding: 12px 10px;
      }

      &.error {
        border-color: var(--error-40);
      }
    }
  }

  .error_text {
    transition: .3s;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--error-40);
    display: block;
    margin-top: 5px;
    align-self: flex-start;
    width: max-content !important;
  }
}

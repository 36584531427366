
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: 10px;
    text-align: center;

    @include tablet {
      margin-bottom: 8px;
    }
  }

  .description {
    margin-bottom: 57px;

    @include tablet {
      margin-bottom: 37.5px;
    }

    @include mobile {
      margin-bottom: 22px;
      text-align: center;
    }

    span {
      font-weight: 600;
    }
  }

  .form {
    max-width: 380px;
    width: 100%;
    margin-bottom: 71px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > p {
      margin-bottom: 14px;
      align-self: flex-start;
      text-align: center;
      width: 100%;

      @include tablet {
        margin-bottom: 8px;
      }
    }

    button {
      &.next {
        margin-top: 47px;
        margin-bottom: 48px;

        @include tablet {
          margin-top: 34px;
          margin-bottom: 30px;
        }

        @include mobile {
          margin-top: 33px;
          margin-bottom: 22px;
        }
      }

      &.resend {
        padding: 0;
        font-weight: 400;
        text-decoration: underline;
      }

      &.resend {
        margin-bottom: 41px;

        @include tablet {
          margin-bottom: 37.5px;
        }

        @include mobile {
          margin-bottom: 11px;
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 1436px;
  padding: 72px 32px;
  margin: 0 auto;
  width: 100%;

  @include tablet {
    padding: 32px 16px;
  }

  @include mobile {
    padding: 32px 24px;
  }

  .progress_bar {
    width: 356px;
    @include mobile {
      display: none;
    }
  }

  .title {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    border-bottom: 1px solid var(--secondary-80);
    padding-bottom: 16px;

    @include tablet {
      margin-top: 32px;
    }

    @include mobile {
      margin-top: 24px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
    padding: 32px 0;
  }

  .actions_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .back {
      font-weight: 400;
      text-decoration: underline;
      font-size: 16px;
      font-style: normal;
      line-height: 150%;
    }
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--secondary-80);
  background: var(--secondary-100);
  padding: 16px;
  transition: .3s;
  width: 100%;

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  }

  .left {
    display: flex;
    gap: 24px;
    align-items: center;
    text-align: left;

    .icon {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--primary-80);

      @include mobile {
        width: 40px;
        height: 40px;
      }

      svg {
        color: var(--neutral-20);
      }
    }

    .text_wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: calc(100% - 60px);
    }
  }

  &.isChecked {
    background: var(--primary-90);

    .left {
      .icon {
        background: var(--primary-50);
      }
    }
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 48px;
  padding-top: 12px;

  @include mobile {
    flex-direction: column;
    align-self: center;
    gap: 24px;
  }

  .card {
    max-width: 345px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    padding: 48px 48px 44px;
    border-radius: 20px;
    border: 1px solid var(--primary-50);
    background: var(--primary-100);
    z-index: 3;

    &.disabled {
      opacity: 0.7;
    }

    .content_wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      width: 100%;
    }

    @include mobile {
      max-width: 312px;
      padding: 24px;
    }

    .title {
      margin-bottom: 8px;
    }

    .description {
      margin-bottom: 16px;
    }

    .shipping {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 16px;

      svg {
        color :var(--primary-20);
      }
    }

    .tag {
      border-radius: 20px;
      background: var(--primary-80);
      padding: 4px 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      text-transform: uppercase;
      color: var(--primary-20);
      margin-bottom: 48px;
    }

    .helper_text {
      transition: .3s;
      color: var(--neutral-50);
      margin-left: 16px;
      display: block;
      margin-top: 4px;
      margin-bottom: 16px;
    }
  }
}

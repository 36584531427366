
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 24px;

  .divider {
    width: 100%;
    border-bottom: 1px solid var(--secondary-80)
  }

  .skeleton {
    height: 66px;
  }

  .accounts {
    width: 100%;
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      padding: 8px 16px;
      border-radius: 10px;
      cursor: pointer;
      width: 100%;

      @include mobile {
        flex-wrap: wrap;
      }

      .data {
        display: flex;
        align-items: center;
        gap: 24px;

        @include mobile {
          flex-wrap: wrap;
          align-items: flex-start;
          width: 100%;
        }

        p {
          font-size: 18px;
          font-weight: 500;
        }
      }

      &:hover {
        transition: .3s;
        background: var(--primary-90);
      }

      .left {
        display: flex;
        align-items: center;
        gap: 24px;
      }
    }
  }

  .interac {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    p {
      font-size: 18px;
      font-weight: 500;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 14px;
    }
  }
}
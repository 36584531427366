
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    border: 1px solid var(--primary-50);
    background: var(--primary-90);
    padding: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 200px;

    img {
        border-radius: 100px;
        width: 50px;
        height: 50px;
    }

    &:hover {
        background: var(--primary-80);
    }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    width: 100%;
  }

  .title {
    margin-bottom: 48px;
    text-align: center;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  .name_wrapper {
    width: 100%;
    display: flex;
    gap: 24px;
    margin-bottom: 24px;

    &:not(:first-child) {
      margin-top: 24px;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  .form {
    width: 584px;
    margin-bottom: 71px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      width: 100%;
    }

    .next {
      margin-top: 48px;
      margin-bottom: 50px;

      @include tablet {
        margin-top: 33px;
        margin-bottom: 34px;
      }

      @include mobile {
        margin-bottom: 23px;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

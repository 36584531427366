
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 1473px;
  margin: 0 auto;
  padding: 88px 32px;
  width: 100%;

  @include tablet {
    padding: 40px 32px;
  }

  @include mobile {
    padding: 32px 24px;
  }

  .content {
    margin-top: 24px;
    padding-top: 32px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-top: 1px solid var(--secondary-80);
    width: 100%;

    @include tablet {
      margin-top: 16px;
    }
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;

  @include mobile {
    width: 100%;
  }

  .progressbar {
    max-width: 473px;
    width: 100%;

    @include mobile {
      width: 100%;
      display: none;
    }

    & > span {
      left: 13% !important;
      width: 75% !important;
    }
  }

  .content {
    padding: 32px 0 24px;
    width: max-content;

    @include mobile {
      width: 100%;
      padding-top: 0;
    }
  }

  .skeleton {
    max-width: 500px;
    width: 100%;
    height: 450px;
    position: absolute;
    margin-top: 10px;
  }

  .back {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    max-width: 500px;
    width: 100%;

    @include tablet {
      margin-top: 8px;
    }

    @include mobile {
      margin-top: 0;
    }

    p {
      text-decoration: underline;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }

  .iframe {
    z-index: 10;
    max-width: 600px;
    width: 100%;
    height: 500px;
    border: none;
  }
}

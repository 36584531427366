
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 8px;
    border-radius: 8px;
    border: 1px solid var(--secondary-80);
    background: var(--neutral-100);
    z-index: 3;

    .left {
      display: flex;
      gap: 8px;
      align-items: center;

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }

      span {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        background: var(--primary-80);
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        }

        &:active {
          box-shadow: none;
        }
      }
    }
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 1436px;
  padding: 72px 32px;
  margin: 0 auto;
  width: 100%;

  @include tablet {
    padding: 32px 16px;
  }

  @include mobile {
    padding: 32px 24px;
  }

  .title {
    margin-bottom: 59px;

    @include tablet {
      margin-bottom: 32px;
    }

    @include mobile {
      margin-bottom: 29px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 45px;
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 1473px;
  margin: 0 auto;
  padding: 88px 32px;

  @include tablet {
    padding-top: 48px;
  }

  @include mobile {
    padding: 40px 24px;

    & > p {
      font-size: 20px !important;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    border-top: 1px solid var(--secondary-80);
    gap: 16px;
    padding: 24px 0;

    @include mobile {
      gap: 0;
    }

    .avatar_wrapper {
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 24px;
      position: relative;
      width: max-content;

      & > div:first-child {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        overflow: hidden;
        background: var(--primary-80);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        transition: .3s;

        &:hover {
          .actions_wrapper {
            transition: .3s;
            opacity: 1;
          }
        }

        @include mobile {
          width: 70px;
          height: 70px;
        }

        img {
          width: 100%;
        }

        p {
          color: var(--primary-20);
          text-transform: uppercase;
          line-height: 100px;
          font-size: 32px;

          @include mobile {
            line-height: 70px;
          }
        }
      }

      .actions_wrapper {
        opacity: 0;
        display: flex;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        justify-content: center;
        gap: 12px;
        position: absolute;
        z-index: 2;
        bottom: 0;

        svg {
          transition: .3s;
          color: black
        }

        button {
          &:first-child:hover {
            svg {
              color: white;
            }
          }
          &:last-child:hover {
            svg {
              color: var(--error-40);
            }
          }
        }
      }
    }

    .row {
      width: 100%;

      .header {
        padding: 26px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--secondary-80);

        .address_actions {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        @include mobile {
          padding: 17px 0;
        }

        button {
          min-width: 110px;

          p {
            color: var(--primary-30);
          }

          @include mobile {
            padding: 10px 8px;
            min-width: auto;

            p {
              display: none;
            }
          }
        }
      }

      .inputs_wrapper {
        padding: 16px 0;
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;

        @include mobile {
          gap: 16px;
        }

        .input_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          gap: 8px;
          width: calc(25% - 18px);

          .title_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            button {
              padding: 4px 10px;
            }
          }

          .footer_wrapper {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
          }

          @include tablet {
            width: calc(33% - 16px);
          }

          @include mobile {
            width: 100%;
          }

          &.large {
            width: calc(50% - 12px);

            @include tablet {
              width: calc(33% - 16px);
            }

            @include mobile {
              width: 100%;
            }
          }
        }
      }

      .phones_emails_wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;

        .phone_email_row {
          display: flex;
          align-items: center;
          gap: 10px;
          width: calc(25% - 18px);
          position: relative;

          &:not(:last-child) {
            margin-right: 16px;
          }

          @include tablet {
            width: calc(33% - 16px);
          }

          @include mobile {
            width: 100%;
          }

          .input_wrapper {
            width: 100%;
          }

          .phone_email_actions {
            display: flex;
            flex-direction: column;
            gap: 8px;
            position: absolute;
            right: -36px;

            button {
              width: 30px;
              height: 30px;
              padding: 0;
            }
          }

          &.email {
            .phone_email_actions {
              top: -6px;
            }
          }

          &.not_email {
            .phone_email_actions {
              //top: 12px;
            }
          }
        }

        .row {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gap: 24px;
        }
      }
    }
  }

  .phone_skeleton {
    height: 56px;
  }

  .email_skeleton {
    height: 56px;
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    width: 100%;
  }

  a {
    font-weight: 600;
  }

  .title {
    margin-bottom: 59px;
    text-align: center;

    @include tablet {
      margin-bottom: 57px;
    }

    @include mobile {
      margin-bottom: 51px;
    }
  }

  .form {
    width: 380px;
    margin-bottom: 71px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      width: 100%;
    }

    & > p {
      margin-bottom: 12px;
      align-self: flex-start;

      @include tablet {
        margin-bottom: 10px;
      }

      @include mobile {
        margin-bottom: 7px;
      }
    }

    & > button {
      margin-top: 45px;
      padding: 14px 25px;

      @include tablet {
        margin-top: 32px;
      }

      @include mobile {
        margin-top: 34px;
      }

      &.back {
        margin-top: 48px;

        @include tablet {
          margin-top: 31px;
        }

        @include mobile {
          margin-top: 24px;
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}


                    @import "src/styles/_mixins.scss";
                
.wrapper {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 20;

  .main_button {
    width: 60px;
    height: 60px;
  }

  .chat_wrapper {
    position: absolute;
    width: 460px;
    height: 700px;
    max-width: 460px;
    max-height: 700px;
    background: white;
    border-radius: 16px;
    box-shadow: 0 41px 91px 0 rgba(7, 7, 7, 0.11), 0 9.158px 20.326px 0 rgba(7, 7, 7, 0.07), 0 2.727px 6.052px 0 rgba(7, 7, 7, 0.04);
    bottom: calc(100% + 16px);
    right: calc(100% - 60px);
    overflow-y: hidden;

    @include mobile {
      max-width: 100vw;
      max-height: 100vh;
      right: -40px;
      bottom: -40px;
      width: 100vw;
      height: 100vh;
      border-radius: 0;
    }

    .header{
      padding: 16px 24px;
      background: var(--secondary-20);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 16px 16px 0 0;

      @include mobile {
        border-radius: 0;
      }

      .back {
        position: absolute;
        left: 24px;
        top: 16px;

        svg {
          color: var(--primary-50)
        }
      }

      .header_text {
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
          color: var(--primary-50)
        }

        p {
          color: var(--primary-50);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .chat_content {
      & > div:first-child {
        padding-bottom: 120px;
      }
      & > div:last-child {
        //bottom: -60px;
      }
    }
  }

  .empty_wrapper {
    width: 100%;
    height: calc(100% - 57px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 32px;

    p {
      text-align: center;
    }
  }
}
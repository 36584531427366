
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .content {
        display: flex;
        flex-direction: column;
        gap: 21px;

        .table_header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
                display: flex;
                align-items: center;
                gap: 16px;
            }
        }
    }
}

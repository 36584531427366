
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include mobile {
    width: 100%;
    gap: 24px;
  }

  .card {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--secondary-80);
    background: var(--secondary-100);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 11px;

    &.isActive {
      background: var(--primary-90);
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: left;

      @include mobile {
        gap: 8px;
      }
    }

    &:first-child {
      .left {
        span {
          max-width: 408px;
        }
      }
    }

    &:last-child {
      .left {
        span {
          max-width: 472px;
        }
      }
    }
  }

  .back {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;

    @include tablet {
      margin-top: 8px;
    }

    @include mobile {
      margin-top: 0;
    }

    p {
      text-decoration: underline;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
}

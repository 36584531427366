
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 404px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-bottom: 40px;
  }

  .header {
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--secondary-80);
    margin-bottom: 32px;
    text-align: center;
  }
}

                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 6px 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    border-radius: 20px;
    width: max-content;

    @include mobile {
        font-size: 10px;
    }

    &.error {
        color: var(--error-20);
        background: var(--error-90);
    }

    &.warning {
        color: var(--primary-10);
        background: var(--primary-80);
    }

    &.success {
        color: var(--success-10);
        background: var(--success-90);
    }
}
